import {
  post
} from "../Http";

export default {
  /**
   * @method 导购员列表
   * @param {*} actId 活动ID
   * @param {*} mobile 手机号
   * @param {*} name 导购员姓名
   * @param {*} page 查询页数
   * @param {*} recharged ？
   * @param {*} size 每页数量
   * @param {*} type ？？
   * @param {*} userId 导购员Id
   */
  getQueryKpiUserList: params =>
    post(`/admin/v1/guide/query_kpi_user_list`, params),
  /**
   * @method 导出导购员列表
   * @param {*} actId 活动ID
   * @param {*} mobile 手机号
   * @param {*} name 导购员姓名
   * @param {*} page 查询页数
   * @param {*} recharged ？
   * @param {*} size 每页数量
   * @param {*} type ？？
   * @param {*} userId 导购员Id
   */
  getExportQueryKpiUser: params =>
    post(`/admin/v1/guide/export_kpi_user_list`, params),

  // 获取导购员详情
  getQueryKpiUserDetail: params =>
    post(`/admin/v1/guide/query_kpi_user_detail?id=${params}`),
  // 获取导购员编辑信息详情
  getEditKpiUserDetail: params =>
    post(`/admin/v1/guide/edit_detail_kpi_user?id=${params}`),
  // 获取导购员编辑信息详情
  getEditKpiUserD: params =>
    post(`/admin/v1/guide/del_kpi_user?id=${params}`),
  // 编辑导购员信息
  editKpiUser: params =>
    post(`/admin/v1/guide/edit_kpi_user`, params),
  // 删除导购员
  delKpiUser: params =>
    post(`/admin/v1/guide/del_kpi_user?id=${params}`),
  // 获取审核列表信息
  getAuditList: params =>
    post(`/admin/v1/guide/query_audit_list`, params),
  // 批量设置审核状态
  getBathAudit: params =>
    post(`/admin/v1/guide/bath_audit`, params),
  // 获取客户关系列表
  getUserRelationList: params =>
    post(`/admin/v1/relation/user_relation_list`, params),
  // 导出客户关系表格
  getExportUserRelation: params =>
    post(`/admin/v1/relation/export_user_relation_list`, params),
  // 查询KPI设置详情
  getQueryByActId: params =>
    post(`/admin/v1/kpi/query_by_act_id?actId=${params}`),
  // 保存KPI设置详情
  getSaveOrUpdate: params =>
    post(`/admin/v1/kpi/save_or_update`, params),

  // 查询KPI数据阶段任务列表
  getStepList: params =>
    post(`/admin/v1/step/list`, params),
  // KPI阶段任务查询奖励总金额和可用金额
  getQueryActAcountByActId: params =>
    post(`/admin/v1/step/query_act_acount_by_act_id?actId=${params}`),
  // KPI阶段任务查询员工任务指标
  getStaffStepByStepId: params =>
    post(`/admin/v1/step/query_staff_step_by_step_id?stepId=${params}`),
  // KPI阶段任务查询品牌任务指标
  getBrandStepByStepId: params =>
    post(`/admin/v1/step/query_brand_step_by_step_id?stepId=${params}`),
  // 新建或编辑阶段任务员工任务指标
  SaveOrUpdateStaffStep: params =>
    post(`/admin/v1/step/save_or_update_staff_step`, params),
  // 新建或编辑阶段任务品牌任务指标
  SaveOrUpdateBrandStep: params =>
    post(`/admin/v1/step/save_or_update_brand_step`, params),
  // 新建或编辑阶段任务
  getSaveOrUpdateStep: params =>
    post(`/admin/v1/step/save_or_update_step`, params),
  // 根据阶段任务查询数据统计列表
  getKpiUserList: params =>
    post(`/admin/v1/step/kpi_user_list`, params),
  // 导出阶段任务：数据统计kpi用户报表
  getExportKpiUserList: params =>
    post(`/admin/v1/step/export_kpi_user_list`, params),
  // 阶段任务查询数据统计列表中 （查询kpi用户详情）
  getKpiUserListDetail: params =>
    post(`/admin/v1/step/kpi_user_detail`, params),

  // 查询KPI奖励金列表
  getQueryBonusList: params =>
    post(`/admin/v1/step/query_bonus_list`, params),
  // 导出KPI奖励金列表
  getExportBonusList: params =>
    post(`/admin/v1/step/export_bonus_list`, params),
  // 查询员工奖励积分列表
  getQueryScoreList: params =>
    post(`/admin/v1/step/query_score_list`, params),
  // 查询品牌奖励积分列表
  getQueryBrandScoreList: params =>
    post(`/admin/v1/step/query_brandScore_list`, params),
  // 导出员工奖励积分列表
  getExportScoreList: params =>
    post(`/admin/v1/step/export_score_list`, params),
  // 导出品牌奖励积分列表
  getExportBrandScoreList: params =>
    post(`/admin/v1/step/export_brandScore_list`, params),

  // 查询品牌惩罚金：手动惩罚品牌列表
  getQueryHandBrandBonusList: params =>
    post(`/admin/v1/step/query_hand_brand_bonus_list`, params),
  // 查询品牌惩罚金：任务未达标品牌列表
  getQueryStepBrandBonusList: params =>
    post(`/admin/v1/step/query_step_brand_bonus_list`, params),
  // 导出品牌惩罚金：手动惩罚品牌列表
  getExportHandBrandBonusList: params =>
    post(`/admin/v1/step/export_hand_brand_bonus_list`, params),
  // 导出品牌惩罚金：任务未达标品牌列表
  getExportStepBrandBonusList: params =>
    post(`/admin/v1/step/export_step_brand_bonus_list`, params),

  // 查询员工排行榜
  getQueryKpiUserRankList: params =>
    post(`/admin/v1/step/query_kpi_user_rank_list`, params),
  // 查询品牌排行榜
  getQueryBrandRankList: params =>
    post(`/admin/v1/step/query_brand_rank_list`, params),
  // 导出员工排行榜
  getExportKpiUserRankList: params =>
    post(`/admin/v1/step/export_kpi_user_rank_list`, params),
  // 导出品牌排行榜
  getExportBrandRankList: params =>
    post(`/admin/v1/step/export_brand_rank_list`, params),

  // 查询直播订单列表
  getQueryLiveOrderList: params =>
    post(`/admin/v1/signingorder/query_live_order_list`, params),
  // 查询过程订单列表
  getQueryProcessOrderList: params =>
    post(`/admin/v1/signingorder/query_process_order_list`, params),
  // 查询秒杀订单列表
  getQuerySecKillOrderList: params =>
    post(`/admin/v1/signingorder/query_secKill_order_list`, params),
  // 导出直播订单列表
  getExportLiveOrderList: params =>
    post(`/admin/v1/signingorder/export_live_order_list`, params),
  // 导出过程订单列表
  getExportProcessOrderList: params =>
    post(`/admin/v1/signingorder/export_process_order_list`, params),
  // 导出秒杀订单列表
  getExportSecKillOrderList: params =>
    post(`/admin/v1/signingorder/export_seckill_order_list`, params),

  // 获取推广海报详情
  getPosterDetail: params =>
    post(`/admin/v1/actpromotion/detail`, params),
  // 保存推广海报详情
  SaveOrUpdatePosterDetail: params =>
    post(`/admin/v1/actpromotion/save_or_update`, params),
}
