var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ProcessOrder_wrapper" },
    [
      _c("el-alert", {
        attrs: {
          title: "该页面仅展示已核销的优惠券明细",
          type: "warning",
          closable: false
        }
      }),
      _c(
        "div",
        { staticClass: "select_wrap mt10" },
        [
          _c("el-input", {
            staticClass: "ml10",
            staticStyle: { width: "180px" },
            attrs: { clearable: true, placeholder: "请输入订单ID" },
            model: {
              value: _vm.ProcessInfo.tradeNo,
              callback: function($$v) {
                _vm.$set(_vm.ProcessInfo, "tradeNo", $$v)
              },
              expression: "ProcessInfo.tradeNo"
            }
          }),
          _c("el-input", {
            staticClass: "ml10",
            staticStyle: { width: "180px" },
            attrs: { clearable: true, placeholder: "请输入客户姓名" },
            model: {
              value: _vm.ProcessInfo.userName,
              callback: function($$v) {
                _vm.$set(_vm.ProcessInfo, "userName", $$v)
              },
              expression: "ProcessInfo.userName"
            }
          }),
          _c("el-input", {
            staticClass: "ml10",
            staticStyle: { width: "180px" },
            attrs: { clearable: true, placeholder: "请输入客户手机号" },
            model: {
              value: _vm.ProcessInfo.mobile,
              callback: function($$v) {
                _vm.$set(_vm.ProcessInfo, "mobile", $$v)
              },
              expression: "ProcessInfo.mobile"
            }
          }),
          _c("el-input", {
            staticClass: "ml10 mr10",
            staticStyle: { width: "180px" },
            attrs: { clearable: true, placeholder: "请输入导购员姓名" },
            model: {
              value: _vm.ProcessInfo.blUserName,
              callback: function($$v) {
                _vm.$set(_vm.ProcessInfo, "blUserName", $$v)
              },
              expression: "ProcessInfo.blUserName"
            }
          }),
          _c(
            "el-select",
            {
              staticClass: "mr10",
              attrs: { placeholder: "品牌商名称" },
              model: {
                value: _vm.ProcessInfo.brandId,
                callback: function($$v) {
                  _vm.$set(_vm.ProcessInfo, "brandId", $$v)
                },
                expression: "ProcessInfo.brandId"
              }
            },
            _vm._l(_vm.BrandList, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id }
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "mr10",
              attrs: { placeholder: "转单类型" },
              model: {
                value: _vm.ProcessInfo.progressType,
                callback: function($$v) {
                  _vm.$set(_vm.ProcessInfo, "progressType", $$v)
                },
                expression: "ProcessInfo.progressType"
              }
            },
            _vm._l(_vm.statusOptions, function(item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value }
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "ml10",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.searchPage }
            },
            [_vm._v("搜索")]
          ),
          _c(
            "el-button",
            {
              staticClass: "ml10",
              attrs: { type: "primary" },
              on: { click: _vm.exportData }
            },
            [_vm._v("导出表格")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "secondsKill_table",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.ProcessList, border: "", stripe: "" }
        },
        [
          _c("el-table-column", { attrs: { prop: "userId", label: "客户ID" } }),
          _c("el-table-column", { attrs: { prop: "userName", label: "姓名" } }),
          _c("el-table-column", {
            attrs: { prop: "userMobile", label: "手机号" }
          }),
          _c("el-table-column", {
            attrs: { prop: "blUserName", label: "归属人" }
          }),
          _c("el-table-column", {
            attrs: { prop: "blShopName", label: "归属人门店" }
          }),
          _c("el-table-column", {
            attrs: { prop: "cardCouponName", label: "优惠券名称" }
          }),
          _c("el-table-column", {
            attrs: { prop: "cardCouponBrandName", label: "优惠券所属品牌" }
          }),
          _c("el-table-column", {
            attrs: { label: "核销状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status == "active"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("未核销")
                        ])
                      : _vm._e(),
                    scope.row.status == "finish"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("已核销")
                        ])
                      : _vm._e(),
                    scope.row.status == "cancel"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("已退单")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "changeAmount", label: "过程转单金额" }
          }),
          _c("el-table-column", {
            attrs: { prop: "progressType", label: "转单类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.progressType == "process_devote_order"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("贡献")
                        ])
                      : _vm._e(),
                    scope.row.progressType == "process_self_order"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("自签")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "finishTime", label: "核销时间" }
          }),
          _c("el-table-column", {
            attrs: { prop: "bonusAmount", label: "奖励发放金额" }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tab-page" },
        [
          _c("div"),
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.ProcessInfo.page,
              "page-size": 10,
              layout: "total, prev, pager, next, jumper",
              total: _vm.ProcessTotal
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }