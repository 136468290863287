<template>
  <div class="ProcessOrder_wrapper">
    <el-alert title="该页面仅展示已核销的优惠券明细" type="warning" :closable="false"></el-alert>
    <div class="select_wrap mt10">
      <el-input
        class="ml10"
        :clearable="true"
        placeholder="请输入订单ID"
        v-model="ProcessInfo.tradeNo"
        style="width:180px"
      ></el-input>
      <el-input
        class="ml10"
        :clearable="true"
        placeholder="请输入客户姓名"
        v-model="ProcessInfo.userName"
        style="width:180px"
      ></el-input>
      <el-input
        class="ml10"
        :clearable="true"
        placeholder="请输入客户手机号"
        v-model="ProcessInfo.mobile"
        style="width:180px"
      ></el-input>
      <el-input
        class="ml10 mr10"
        :clearable="true"
        placeholder="请输入导购员姓名"
        v-model="ProcessInfo.blUserName"
        style="width:180px"
      ></el-input>
      <el-select v-model="ProcessInfo.brandId" placeholder="品牌商名称" class="mr10">
        <el-option v-for="item in BrandList" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <el-select v-model="ProcessInfo.progressType" placeholder="转单类型" class="mr10">
        <el-option
          v-for="item in statusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-button
        type="primary"
        class="ml10"
        icon="el-icon-search"
        @click="searchPage"
      >搜索</el-button>
      <el-button type="primary" class="ml10" @click="exportData">导出表格</el-button>
    </div>
    <el-table class="secondsKill_table" :data="ProcessList" border stripe style="width: 100%">
      <el-table-column prop="userId" label="客户ID"></el-table-column>
      <el-table-column prop="userName" label="姓名"></el-table-column>
      <el-table-column prop="userMobile" label="手机号"></el-table-column>
      <el-table-column prop="blUserName" label="归属人"></el-table-column>
      <el-table-column prop="blShopName" label="归属人门店"></el-table-column>
      <el-table-column prop="cardCouponName" label="优惠券名称"></el-table-column>
      <el-table-column prop="cardCouponBrandName" label="优惠券所属品牌"></el-table-column>
      <el-table-column label="核销状态">
        <template slot-scope="scope">
          <el-tag type="primary" v-if="scope.row.status=='active'">未核销</el-tag>
          <el-tag type="primary" v-if="scope.row.status=='finish'">已核销</el-tag>
          <el-tag type="primary" v-if="scope.row.status=='cancel'">已退单</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="changeAmount" label="过程转单金额"></el-table-column>
      <el-table-column prop="progressType" label="转单类型">
        <template slot-scope="scope">
          <el-tag type="primary" v-if="scope.row.progressType=='process_devote_order'">贡献</el-tag>
          <el-tag type="primary" v-if="scope.row.progressType=='process_self_order'">自签</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="finishTime" label="核销时间"></el-table-column>
      <el-table-column prop="bonusAmount" label="奖励发放金额"></el-table-column>
    </el-table>
    <div class="tab-page">
      <div></div>
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="ProcessInfo.page"
        :page-size="10"
        layout="total, prev, pager, next, jumper"
        :total="ProcessTotal"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import GuideAjax from "@/utils/https/modules/Guide.request.js";
import ActivityAjax from "@/utils/https/modules/Activity.request.js";
export default {
  name: "ProcessOrder", //
  components: {},
  props: {
    activityID: String,
  },
  data() {
    return {
      BrandList: [],
      nodata: "",
      nobool: false,
      statusOptions: [
        { value: "process_devote_order", label: "贡献" },
        { value: "process_self_order", label: "自签" },
      ],
      ProcessInfo: {
        actId: 0,
        blUserName: "",
        brandId: "",
        mobile: "",
        page: 1,
        progressType: "",
        size: 10,
        tradeNo: "",
        userName: "",
      },
      ProcessList: [],
      ProcessTotal: 0,
      BrandList: [],
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 表格 页码请求
    handleCurrentChange(val) {
      this.ProcessInfo.page = val;
      this.getQueryProcessOrderList();
    },
    searchPage() {
      this.ProcessInfo.page = 1;
      this.getQueryProcessOrderList();
    },
    // 查询过程订单列表
    async getQueryProcessOrderList() {
      try {
        this.ProcessInfo.actId = this.activityID;
        const {
          data: { list, total },
        } = await GuideAjax.getQueryProcessOrderList(this.ProcessInfo);
        this.ProcessList = list;
        this.ProcessTotal = total;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 导出过程订单列表
    async exportData() {
      try {
        const data = await GuideAjax.getExportProcessOrderList(
          this.ProcessInfo
        );
        const link = document.createElement("a");
        let blob = new Blob([data.data], { type: "application/vnd.ms-excel" });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "过程订单统计.xls");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 查询品牌商列表
    async getActivityBrandList() {
      try {
        const {
          data: { list },
        } = await ActivityAjax.getActivityBrandList({
          actId: this.activityID,
          catId: "",
          name: "",
          page: 1,
          size: 1000,
        });
        this.BrandList = list;
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
  created() {
    this.getQueryProcessOrderList();
    this.getActivityBrandList();
  },
  mounted() {},
};
</script>

<style lang="scss">
.ProcessOrder_wrapper {
  .select_wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .secondsKill_table {
    margin-top: 20px;
    .el-table__body {
      .cell {
        span {
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
  }
  .tab-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
  }
}
</style>
